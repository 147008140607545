import addFlash from '../../react/utils/flashbag';
import handleErrorResponse from '../../react/utils/utils';
import { DUTCH_DATE, DUTCH_TIME, DUTCH_DATE_TIME } from "../../react/utils/constants";

window.addFlash = addFlash;

window.constants = {
	DUTCH_DATE,
	DUTCH_TIME,
	DUTCH_DATE_TIME,
};

window.handleInternalErrorResponse = handleErrorResponse;
