/*global AnchorPosition_getPageOffsetLeft AnchorPosition_getPageOffsetTop*/
let hidden = [];

/**
 * Hide all selectboxes that are overlapped by a layer
 */
function hideSelectBoxes(layer) {
	let x = AnchorPosition_getPageOffsetLeft(layer);
	let y = AnchorPosition_getPageOffsetTop(layer);
	let w = layer.offsetWidth;
	let h = layer.offsetHeight;

	let allSelect = document.getElementsByTagName('select');
	// keep let out of for, see issue: https://github.com/babel/minify/issues/430 and ~485
	let i;
	for (i = 0; i < allSelect.length; i++) {
		let sx = AnchorPosition_getPageOffsetLeft(allSelect[i]);
		let sy = AnchorPosition_getPageOffsetTop(allSelect[i]);
		let sw = allSelect[i].offsetWidth;
		let sh = allSelect[i].offsetHeight;
		if ((x + w) > sx && (y + h) > sy) {
			// BottomRight of layer is below TopLeft of select
			if (x < (sx + sw) && y < (sy + sh)) {
				hidden[hidden.length] = allSelect[i];
				allSelect[i].style.visibility = 'hidden';
			}
		}
	}
}
// Make function hideSelectBoxes globally available
window.hideSelectBoxes = hideSelectBoxes;

/**
 * Show all previously hidden selectboxes
 */
function showSelectBoxes() {
	// keep let out of for, see issue: https://github.com/babel/minify/issues/430 and ~485
	let i;
	for (i = 0; i < hidden.length; i++) {
		hidden[i].style.visibility = 'visible';
	}
}
// Make function showSelectBoxes globally available
window.showSelectBoxes = showSelectBoxes;
