/**
 * A crude way to get the handle from the URL
 * @returns {string}
 *
 * made a change on how to export the function because for react we use import and from normal javascript
 * file we use require and export default is not compatible with require
 */

function extractFunction() {
	const endOfString = window.location.pathname.indexOf('/', 1) > 0 ? window.location.pathname.indexOf('/', 1) - 1 : undefined;
	return window.location.pathname.substr(1, endOfString);
}

export default extractFunction;
export const extractHandleFromUrl = extractFunction;
