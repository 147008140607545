import $ from 'jquery';
import 'bootstrap-notify';
import 'animate.css';

// Set defaults
$.notifyDefaults({
	placement: {
		from: 'top',
		align: 'center',
	},
	animate: {
		enter: 'animate__animated animate__bounceIn',
		exit: 'animate__animated animate__bounceOut'
	},
	timer: 100,
	// necessary since we can't upgrade to bootstrap4-notify
	// because it doesn't have the col classes in the template
	template: '' +
		'<div data-notify="container" class="col-11 col-sm-4 alert alert-{0}" role="alert">' +
			'<button type="button" aria-hidden="true" class="close" data-notify="dismiss">&times;</button>' +
			'<span data-notify="icon"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span>' +
			'<div class="progress" data-notify="progressbar">' +
				'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
			'</div>' +
			'<a href="{3}" target="{4}" data-notify="url"></a>' +
		'</div>'
});
