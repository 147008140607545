import extractHandleFromUrl from './extract_handle';

const apiVersion = 'v1';
const apiBaseRoute = `/api/${apiVersion}`;
export const handle = extractHandleFromUrl();
const getOptionalIdPath = id => (id ? `/${id}` : '');

/**
 * A list of routes until we get backend_router working correctly
 */
export const getExamComponentTypesRoute = () => (`${apiBaseRoute}/exam_component_types`);
export const submitPlannableProductRoute = () => (`${apiBaseRoute}/plannable_products`);
export const createExamComponentTypeRoute = () => (`/${handle}/beheer/examenonderdelen/nieuw`);
export const menuCollapsedRoute = () => (`/${handle}/menu/collapse`);
export const getInvoiceDetailsBase = () => (`/${handle}/financieel/factuur_details`);
export const getStudentDetailsBase = (id, params = '') => (`/${handle}/leerlingen/details?id=${id}${params}`);
export const instructorsPage = instructorId => (`/${handle}/instructeurs?selectedInstructor=${instructorId}`);
export const getCompanyDetailsBase = id => (`/${handle}/bedrijven/details?id=${id}`);
export const invoiceOverview = () => (`/${handle}/financieel/facturen`);
export const getTasksRoute = () => (`${apiBaseRoute}/tasks`);
export const getTasksOverviewRoute = () => (`/${handle}/taken`);
export const getExamMoments = () => (`/${handle}/agenda/capaciteiten`);
export const getPaymentsOverview = () => (`/${handle}/financieel/kasboek`);
export const getCourseTrackingDetails = id => (`/${handle}/nascholing?page=nascholing/details&id=${id}`);

export const getTrainingRoute = () => (`${apiBaseRoute}/trainings`);

export const getCourses = () => (`${apiBaseRoute}/courses`);
export const getCategories = () => (`${apiBaseRoute}/categories`);
export const getVehicles = () => (`${apiBaseRoute}/vehicles`);

export const getLocations = () => (`${apiBaseRoute}/locations`);
export const getPlannableInstructors = () => (`${apiBaseRoute}/plannable-instructors`);
export const getInstructor = id => (`${apiBaseRoute}/instructors/${id}`);
export const getCommonPresets = () => (`${apiBaseRoute}/comment-presets`);
export const getHourTypes = () => (`${apiBaseRoute}/hour_types`);

export const getDefaultAppointment = () => `${apiBaseRoute}/appointments/default`;
export const getVacationAppointments = id => (`${apiBaseRoute}/appointments${id ? `/${id}` : '/vacations/default'}`);
export const submitVacationAppointment = id => (`${apiBaseRoute}/appointments/vacations${getOptionalIdPath(id)}`);
export const getPayments = () => (`${apiBaseRoute}/payments`);
export const getInvoices = () => (`${apiBaseRoute}/invoices`);
export const getStudents = () => (`${apiBaseRoute}/students`);
export const getRegions = () => (`${apiBaseRoute}/regions`);
export const submitCode95Cycles = id => (`${apiBaseRoute}/code95_cycles${getOptionalIdPath(id)}`);
export const getPracticeExams = () => (`${apiBaseRoute}/attendances/practice-exams`);
export const getReplannablePracticeExams = () => (`${apiBaseRoute}/attendances/replannable-practice-exams`);
export const getInstructorsBillableHours = () => (`${apiBaseRoute}/instructors/billable-hours`);
export const getInstructorsStudentAssignments = () => (`${apiBaseRoute}/instructors/student-assignment`);
export const getTheoryMoment = id => `${apiBaseRoute}/theory_moments${id ? `/${id}` : ''}`;

export const agendaNote = id => (`${apiBaseRoute}/agenda_notes${getOptionalIdPath(id)}`);
export const appointment = id => (`${apiBaseRoute}/appointments${getOptionalIdPath(id)}`);
export const block = id => `${apiBaseRoute}/resource_blocks${getOptionalIdPath(id)}`;
export const lessonProposal = id => `${apiBaseRoute}/resource_blocks/${id}/propose`;
export const blockLessonProposals = id => `${apiBaseRoute}/resource_blocks/instructor/${id}/lesson_proposals`;
export const saveLessonProposals = () => `${apiBaseRoute}/student_lesson_proposals`;
export const getAppointmentEvent = id => (`${apiBaseRoute}/appointments/${id}/event`);

export const settleInvoices = id => (`/${handle}/invoices/${id}/settle`);

export const getEmailListUrl = () => (`${apiBaseRoute}/emails`);
export const sendCustomEmail = (emailType, recipientId) => (`/${handle}/leerlingen/send_custom_mail?emailType=${emailType}&receiverId=${recipientId}`);
export const getEmail = emailId => `/${handle}/email/${emailId}/details`;

export const getPubSubTopics = () => `${apiBaseRoute}/pub-sub-subscriptions/topics`;
export const pubSubSubscriptionsEndpoint = id => `${apiBaseRoute}/pub-sub-subscriptions${getOptionalIdPath(id)}`;

export const getKilometerTypesUrl = () => `${apiBaseRoute}/vehicle_kilometer_types`;
export const getMaxVehicleKilometersUrl = () => `${apiBaseRoute}/vehicle_kilometers/max`;
export const kilometersEndpoint = id => `${apiBaseRoute}/vehicle_kilometers${getOptionalIdPath(id)}`;
export const notPlannedStudentsUrl = () => `${apiBaseRoute}/students/latest-lesson-before-four-weeks-ago`;

export const getLongLatitude = () => 'https://nominatim.openstreetmap.org/search';

export const getSetting = () => `${apiBaseRoute}/settings`;
export const getExamCapacities = appointmentId => `${apiBaseRoute}/appointments/${appointmentId}/exam-capacities`;
export const getSelectableOptions = () => `${apiBaseRoute}/agenda/selectable-options`;

export const getELearningResultsForTraining = trainingId => `/${handle}/training/${trainingId}/ELearningResults`;
export const getTheoryOrderStatus = orderId => `/${handle}/externalTheoryProductOrder/${orderId}/status`;

export const getDrivingSchoolInformation = administrationId => `${apiBaseRoute}/driving-school-information/${administrationId}`;

export const getTheoryAppointmentsForExamCapacity = examCapacityId => `${apiBaseRoute}/exam-capacities/${examCapacityId}/matching-theory-appointments`;
export const getExamCapacity = examCapacityId => `${apiBaseRoute}/exam_capacities/${examCapacityId}`;

export const addAttendanceToAppointment = appointmentId => `${apiBaseRoute}/appointments/${appointmentId}/attendances`;

export const getCbrRequest = cbrRequestId => `${apiBaseRoute}/cbr_requests/${cbrRequestId}`;
