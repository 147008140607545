// Function that parses error messages from :
// - AccessDenied Exception (and similar)
// - JsonProblemResponseTrait

const handleErrorResponse = (status, data) => {
	const HttpConflictCode = 409;

	let errorMessages;
	let warningMessages;

	if(status === HttpConflictCode) {
		warningMessages = (data.violations || data.warnings.violations).map(violation => violation.message);
	} else if(data?.violations || data?.errors?.violations) {
		errorMessages = (data.violations || data.errors.violations).map(violation => violation.message);
	} else if(data?.detail) {
		errorMessages = [data.detail];
	} else {
		errorMessages = ['U mag deze bewerking niet uitvoeren'];
	}

	if(!errorMessages && !warningMessages) {
		errorMessages = ['Er is iets misgegaan. Probeer het opnieuw'];
	}
	return {
		errorMessages: errorMessages || [],
		warningMessages: warningMessages || []
	};
};

export default handleErrorResponse;
