/* global require */
var tabs = require('./tabs.js');

$(document).ready(function() {
	/* .unfocusable -> do not allow focus, skip in tabIndex */
	$('form .unfocusable, fieldset .unfocusable').attr('tabIndex', -1).on('focus', function() {
		$(this).blur();
	});
});

function parentReload() {
	top.window.opener.location.reload(true);
}
// Make function parentReload globally available
window.parentReload = parentReload;

function parentSecondReload() {
	top.window.opener.location.reload(true);
}
// Make function parentSecondReload globally available
window.parentSecondReload = parentSecondReload;

function reloadPage(fullPageReload = false) {
	if($('#tabs').length && fullPageReload === false) {
		// if inside tab page reload active tab
		tabs.reloadActiveTab();
	} else {
		// reload the page
		window.top.location.reload(true);
	}
}
// Make function reloadPage globally available
window.reloadPage = reloadPage;

function showErrorNotification(message, target) {
	showBootstrapNotification(message, target, 'danger');
}
window.showErrorNotification = showErrorNotification;

function showWarningNotification(message, target) {
	showBootstrapNotification(message, target, 'warning');
}
window.showWarningNotification = showWarningNotification;

function showBootstrapNotification(message, target, type) {
	// Create Bootstrap Alert Element
	let $alertCloseButton = $('<button></button>')
		.attr('type', 'button')
		.addClass('close')
		.html('&times;')
		.on('click', function() {
			$alert.fadeOut(200);
		});

	let $alert = $('<div></div>')
		.addClass('alert alert-' + type + ' fade show')
		.html(message)
		.append($alertCloseButton)
		.prependTo($('#' + target));
}

function closePopUp() {
	parentReload();
	window.parent.close();
}

window.closePopUp = closePopUp;
