/**
 * This script is responsible for looking up an address from PostcodeNL via a postcode and house number combination
 *
 * Requirements for this script to work:
 * - the address fields should be wrapped in an element with data-address-lookup-url="<route to lookup controller>"
 * - only 1 postcode/house-number/street-name/city element should be in the wrapper-element
 * - the classes 'postcode', 'house-number', 'street-name' and 'city' should be added to the applicable inputs
 */
$(document).ready(function() {
	const addressLookupContainerSelector = '[data-address-lookup-url]',
		postcodeSelector = '.postcode',
		houseNumberSelector = '.house-number',
		streetNameSelector = '.street-name',
		citySelector = '.city';

	let streetName = '',
		city = '';

	function lookupAddress() {
		const $addressContainer = $(this).closest(addressLookupContainerSelector),
			$postcode = $addressContainer.find(postcodeSelector),
			$houseNumber = $addressContainer.find(houseNumberSelector),
			$streetName = $addressContainer.find(streetNameSelector),
			$city = $addressContainer.find(citySelector);

		let postcode = $postcode.val().toUpperCase().replace(' ', ''),
			houseNumber = $houseNumber.val();

		if(postcode.length > 0 && houseNumber.length > 0) {
			// validate postcode and house number adhere to the format expected by PostcodeNL
			if(
				postcode.length !== 6
				|| !postcode.match(/^[0-9]{4}[a-z]{2}$/gi)
				|| !(houseNumber > 0 && houseNumber <= 99999)
			) {
				invalidateAddress($postcode, $houseNumber, $streetName, $city);
				streetName = '';
				city = '';
				return;
			}

			$.ajax({
				type: 'POST',
				url: $addressContainer.data('addressLookupUrl'),
				data: {
					houseNumber: houseNumber,
					postcode: postcode
				},
				dataType: 'json',
				success: function(response) {
					// After changing the values, trigger change so form_validation.js checks the validity of the form.
					$streetName.val(response.street).change();
					$city.val(response.city).change();
					approveAddress($postcode, $houseNumber, $streetName, $city);
					streetName = response.street;
					city = response.city;
				},
				error: function(jqXhr) {
					streetName = '';
					city = '';
					// code 404 indicates that PostcodeNL could not find an address, in this case we make the postcode
					// and house-number fields invalid; for any other error we simply leave the fields white
					if(jqXhr.status === 404) {
						invalidateAddress($postcode, $houseNumber, $streetName, $city);
					} else {
						clearValidity($postcode, $houseNumber, $streetName, $city);
					}
				}
			});
		}
	}

	/**
	 * This function check whether the street name and city we got from the lookup is the same as the user input.
	 */
	function validateAddress() {
		// $(this) in this context should be the streetName or city element
		const $addressContainer = $(this).closest(addressLookupContainerSelector),
			$postcode = $addressContainer.find(postcodeSelector),
			$houseNumber = $addressContainer.find(houseNumberSelector),
			$streetName = $addressContainer.find(streetNameSelector),
			$city = $addressContainer.find(citySelector);

		let userStreetName = $streetName.val();
		let userCity = $city.val();

		if(streetName != '' || city != '') {
			let userInputIdentical = userStreetName === streetName && userCity === city;
			let className = userInputIdentical ? 'auto-filled-is-valid' : 'auto-filled-is-invalid';
			clearValidity($postcode, $houseNumber, $streetName, $city);
			$postcode.addClass(className);
			$houseNumber.addClass(className);
			$streetName.addClass(className);
			$city.addClass(className);
		}
	}

	function invalidateAddress($postcode, $houseNumber, $streetName, $city) {
		clearValidity($postcode, $houseNumber, $streetName, $city);
		$postcode.addClass('auto-filled-is-invalid');
		$houseNumber.addClass('auto-filled-is-invalid');
	}

	function approveAddress($postcode, $houseNumber, $streetName, $city) {
		clearValidity($postcode, $houseNumber, $streetName, $city);
		$postcode.addClass('auto-filled-is-valid');
		$houseNumber.addClass('auto-filled-is-valid');
		$streetName.addClass('auto-filled-is-valid');
		$city.addClass('auto-filled-is-valid');
	}

	function clearValidity($postcode, $houseNumber, $streetName, $city) {
		$postcode.removeClass('auto-filled-is-valid auto-filled-is-invalid');
		$houseNumber.removeClass('auto-filled-is-valid auto-filled-is-invalid');
		$streetName.removeClass('auto-filled-is-valid auto-filled-is-invalid');
		$city.removeClass('auto-filled-is-valid auto-filled-is-invalid');
	}

	$(document)
		.on('blur', addressLookupContainerSelector + ' ' + postcodeSelector + ', ' + addressLookupContainerSelector + ' ' + houseNumberSelector, lookupAddress)
		.on('keyup', addressLookupContainerSelector + ' ' + streetNameSelector + ', ' + addressLookupContainerSelector + ' ' + citySelector, validateAddress);
});
