/* global $ */
$(document).ready(function() {
	showFlashMessages();
});

$(document).ajaxComplete(function() {
	showFlashMessages();
});

const showFlashMessages = () => {
	const STORAGE_KEY = 'flash-messages';

	let notifications = window.__FLASH_MESSAGES__ || [];
	let flashMessages = JSON.parse(window.sessionStorage.getItem(STORAGE_KEY)) || [];

	let messages = [...notifications, ...flashMessages];

	window.sessionStorage.removeItem(STORAGE_KEY);
	delete window.__FLASH_MESSAGES__;

	messages.map(n => (
		$.notify({
			message: n.message
		}, {
			delay: n.delay,
			type: n.type || 'info'
		})
	));
}

export default showFlashMessages;
