function URLEncode(plaintext) {
	// The Javascript escape and unescape functions do not correspond
	// with what browsers actually do...
	let safeChars = '0123456789' +		// Numeric
		'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +	// Alphabetic
		'abcdefghijklmnopqrstuvwxyz' +
		'-_.!~*\'()';					// RFC2396 Mark characters
	let hexChars = '0123456789ABCDEF';

	let encoded = '';
	// keep let out of for, see issue: https://github.com/babel/minify/issues/430 and ~485
	let i;
	for (i = 0; i < plaintext.length; i++) {
		let char = plaintext.charAt(i);
		if (char === ' ') {
			encoded += '+';				// x-www-urlencoded, rather than %20
		} else if (safeChars.indexOf(char) !== -1) {
			encoded += char;
		} else {
			let charCode = char.charCodeAt(0);
			if (charCode > 255) {
				alert('Unicode Character \''
					+ char
					+ '\' cannot be encoded using standard URL encoding.\n' +
					'(URL encoding only supports 8-bit characters.)\n' +
					'A space (+) will be substituted.');
				encoded += '+';
			} else {
				encoded += '%';
				encoded += hexChars.charAt((charCode >> 4) & 0xF);
				encoded += hexChars.charAt(charCode & 0xF);
			}
		}
	} // for

	return encoded;
}

function openDialog(source, dialogwidth, dialogheight, title, scrolling) {
	const scrollValue = scrolling === undefined ? 'auto' : scrolling;

	const encodedSource = URLEncode(source);
	let dialeft = ((screen.width - dialogwidth) / 2);
	let diatop = ((screen.height - dialogheight) / 2);

	let adaptedDialogHeight = dialogheight;
	if (navigator.appName === 'Microsoft Internet Explorer') {
		adaptedDialogHeight += 25;
	}
	if (navigator.appName === 'Microsoft Internet Explorer' && navigator.userAgent.indexOf('MSIE 10') === -1) {
		if (window.navigator.userAgent.indexOf('SV1') !== -1) {
			adaptedDialogHeight += 25;
		}
		window.showModelessDialog('/kantoor/templates/default/frame.php?source=../../..' + encodedSource + '&title=' + title + '&scrolling=' + scrollValue + '', window, 'center=yes; dialogwidth=' + dialogwidth + 'px; dialogheight=' + adaptedDialogHeight + 'px; dialogleft=' + dialeft + 'px; dialogtop=' + diatop + 'px; help=no; status=no; resizable=no;');
	}
	else {
		window.open('/kantoor/templates/default/frame.php?source=../../..' + encodedSource + '&title=' + title + '&scrolling=' + scrollValue + '', title, 'toolbar=0, scrollbars=0, location=0, statusbar=0, menubar=0, resizable=0, width=' + dialogwidth + ', height=' + adaptedDialogHeight + ', left=' + dialeft + ', top=' + diatop + ',modal=yes');
	}
}
// Make function openDialog globally available
window.openDialog = openDialog;