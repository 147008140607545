/* global Rollbar */
/**
	Generic DataTables initialisation

	usage:
	Add a `data-datatable`  attribute the `<table />` element

	Optionally pass a configuration object:
		`<table data-datatable='{ "order":[[1, "desc"]] }' />`

	Please note the single quotes.
*/

function dataTables() {
	var $tables = $('[data-datatable]');

	$tables.each(function() {
		var $this = $(this);

		if($.fn.DataTable.isDataTable($this)) {
			return true;
		}

		var headers = $this.find('thead > tr:first-child > th');
		var cells = $this.find('tbody > tr:first-child > td');

		if(headers.length !== cells.length) {
			// DataTables will error when there is a mismatch between the number of headers and cells
			// this happens with `<td colspan="5">No data found</td>`rows.
			return true;
		}

		var defaultConfig = {
			paging: false,
			searching: false
		};

		var config = $this.attr('data-datatable');

		try {
			// DataTables expects integers, not numerical strings
			config = JSON.parse(config, function(k, v) {
				return typeof v === 'object' || isNaN(v) ? v : parseInt(v, 10);
			});
		} catch (error) {
			config = {};
			if(typeof Rollbar !== 'undefined') {
				Rollbar.error(error);
			} else {
				console.error(error); // eslint-disable-line no-console
			}
		}

		var dataTableConfig = $.extend(true, defaultConfig, config);
		$this.DataTable(dataTableConfig);
	});
}


$(document).ready(function() {
	dataTables();
});
$(document).ajaxComplete(function(e) {
	dataTables();
});
