/* global global, module */

// define functions inside the tabs namespace
let tabs = typeof window.tabs === 'undefined' ? {} : window.tabs;
$.extend(tabs, {
	TABS_ID: '#tabs',
	REDIRECT_TAB_CLASS: '.redirectTab',

	// this function is used when redirecting the tab from the loaded content e.g student history page to send email
	redirectTab: function(e) {
		e.preventDefault();
		e.stopPropagation();
		let pageToLoad = $(e.target).closest(tabs.REDIRECT_TAB_CLASS).attr('href');
		tabs.loadTab(pageToLoad);
		// unfortunately this was added because of the student details mobile version
		// when clicking on student's email address
		if(window.innerHeight < $(tabs.TABS_ID).offset().top) {
			tabs.scrollToTabs();
		}
	},

	showTab: function() {
		let $this = $(this);
		let loadurl = $this.attr('href');
		let target = $this.attr('data-target');

		$(target).html('<div class="ajaxContainer loading">').load(loadurl);

		$this.tab('show');
		return false;
	},


	loadTab: function(url) {
		$('.tab-content #details').append('<div class="tab-loading" id="tab-loading">').load(url);
	},


	reloadActiveTab: function() {
		// this function is used when doing an action inside a tab to reload the current active tab

		tabs.loadTab($('#tabs a.active[data-toggle=tabajax]').attr('href'));
	},


	scrollToTabs: function() {
		$('html, body').animate({
			scrollTop: $(tabs.TABS_ID).offset().top - 56
		}, 500);
	}
});


/*
 * @Description: Close modal or show close button in modal
 *
 * @param {boolean} autoClose - if the modal should be closed immediately
 * @param {boolean} reload - if the page should be reloaded
 */
(function($) {
	$.fn.closeActions = function(autoClose, reload, fullPageReload = false) {
		var $modal = $(this);
		var $btnPrimary = $(this).find('#modal-submit');
		// remove all buttons, then add primary back
		$modal.find('.modal-footer').html('').append($btnPrimary);

		// make button clickable, close modal, do not submit
		$btnPrimary.prop('disabled', false).val('Sluiten').unbind().on('click', function() {
			$modal.modal('hide');
			if(reload) {
				window.reloadPage(fullPageReload);
			}
			return false;
		});

		if(autoClose) {
			$btnPrimary.click();
		}
	};
})(jQuery);


// define a top-level namespace for usage (only if it didn't exist already)
global.tabs = tabs;
module.exports = tabs;

/* Live triggers */
$(document).ready(function() {
	$(document).on('click', tabs.REDIRECT_TAB_CLASS, tabs.redirectTab);
	$(tabs.TABS_ID).on('click', '[data-toggle="tabajax"]', tabs.showTab);
});
