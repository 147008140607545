/* global require, $ */
const { menuCollapsedRoute } =  require('../../react/utils/routes');
/**
 * Javascript for the main menu; contains toggle logic
 */
$(document).ready(function() {
	const SIDEBAR_EXPANDED_CLASS = 'expanded';
	const isTouch = 'ontouchstart' in document.documentElement;
	const $sidebar = $('#sidebar');
	const $button = $('[data-toggle="#sidebar"]');
	const $submenus = $('[data-parent="#sidebar-nav"]');


	$('[data-js-href]').on('click', function(e) {
		if(!isTouch) {
			e.preventDefault();
			e.stopPropagation();

			const $this = $(this);
			window.location.href = $this.data('js-href');
		}
	});

	// Different behavior for touch devices
	if(isTouch) {
		$sidebar.removeClass(SIDEBAR_EXPANDED_CLASS);
	}


	// Toggle sidebar
	$('[data-toggle="#sidebar"]').on('click', function() {
		if($sidebar.hasClass(SIDEBAR_EXPANDED_CLASS)) {
			$sidebar.removeClass(SIDEBAR_EXPANDED_CLASS);

			if(isTouch) {
				$button.find('.glyphicons-menu').removeClass('opacity-none');
				$button.find('.glyphicons-times').addClass('opacity-none');
			}
			!isTouch && $.post(menuCollapsedRoute(), {
				'menu_collapsed': 0
			});

		} else {
			$sidebar.addClass(SIDEBAR_EXPANDED_CLASS);

			if(isTouch) {
				$button.find('.glyphicons-menu').addClass('opacity-none');
				$button.find('.glyphicons-times').removeClass('opacity-none');
			}

			!isTouch && $.post(menuCollapsedRoute(), {
				'menu_collapsed': 1
			});
		}
	});


	// Toggle chevrons for submenus
	$submenus.on('show.bs.collapse', function(e) {
		$(e.target)
			.siblings('[data-toggle]')
			.find('.glyphicons-chevron-down')
			.removeClass('glyphicons-chevron-down')
			.addClass('glyphicons-chevron-up');
	});

	$submenus.on('hide.bs.collapse', function(e) {
		$(e.target)
			.siblings('[data-toggle]')
			.find('.glyphicons-chevron-up')
			.removeClass('glyphicons-chevron-up')
			.addClass('glyphicons-chevron-down');
	});
});
