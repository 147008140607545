export const DUTCH_DATE = 'DD-MM-YYYY';
export const DUTCH_TIME = 'HH:mm';
export const DUTCH_DATE_TIME = `${DUTCH_DATE} ${DUTCH_TIME}`;
export const API_DATE_TIME = 'YYYY-MM-DDTHH:mm:ssZ';

// TODO: Move to constants/events.js
export const TIMES_CHANGED_EVENT = 'agenda_popup_times_changed';
export const INSTRUCTOR_CHANGE_EVENT = 'agenda_popup_instructor_changed';
export const DUTCH_NUMBER_FORMAT = new Intl.NumberFormat('nl-NL');

export const UNDO_MOVE_EVENT = 'agenda_undo_move';
export const LESSON_PROPOSALS_EVENT = 'lesson_proposals_event';
