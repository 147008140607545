import moment from '../../react/utils/moment';

$(document).ready(function() {
	const getDatesFromSelectedOption = (selectedBlock, selectedYear) => {
		const year = moment(selectedYear, 'YYYY');
		let start;
		let stop;

		if(selectedBlock.startsWith('kw')) {
			const quarter = moment(year).quarter(parseInt(selectedBlock.substring(2), 10));
			start = moment(quarter).startOf('quarter');
			stop = moment(quarter).endOf('quarter');
		} else if(selectedBlock.startsWith('m')) {
			const month = moment(year).month(parseInt(selectedBlock.substring(1), 10) - 1);
			start = moment(month).startOf('month');
			stop = moment(month).endOf('month');
		} else if(selectedBlock.startsWith('w')) {
			const selectedWeek = moment()
				.weekYear(year.year())
				.week(parseInt(selectedBlock.substring(1), 10));

			start = moment(selectedWeek).startOf('week');
			stop = moment(selectedWeek).endOf('week');
		} else {
			start = moment(year).startOf('year');
			stop = moment(year).endOf('year');
		}

		return { start, stop };
	};

	window.parsePeriod = getDatesFromSelectedOption;
});
