/* global ga */

const { extractHandleFromUrl } = require('../../react/utils/extract_handle');

/**
 * Adds Google Analytics event-tracking to any element that has the 'ga-event-category' and 'ga-event-action' data
 *  attributes.
 *
 * Supported data attributes:
 *  Field name          Value type    Required    Description
 *  ga-event-category   text          yes         Typically the object that was interacted with (e.g. 'Video')
 *  ga-event-action	    text          yes         The type of interaction (e.g. 'play')
 *  ga-event-label      text          no          Useful for categorizing events (e.g. 'Fall Campaign')
 *  ga-event-value      integer	      no          A numeric value associated with the event (e.g. 42)
 */
$(document).on('click', '[data-ga-event-category][data-ga-event-action]', function() {
	var $this = $(this);
	// required fields
	var gaEventCategory = $this.data('gaEventCategory'),
		gaEventAction = $this.data('gaEventAction');

	// optional fields
	var gaEventLabel = $this.data('gaEventLabel'),
		gaEventValue = $this.data('gaEventValue');

	if(gaEventValue === undefined && $this.is(':checkbox')) {
		gaEventValue = $this.is(':checked') ? 1 : 0;
	}

	ga('send', 'event', gaEventCategory, gaEventAction, gaEventLabel, gaEventValue);
	let eventName = gaEventAction;
	if(gaEventLabel) {
		eventName += '(' + gaEventLabel + ')';
	}
	const gaData = { 'eventCategory': gaEventCategory, 'handle': extractHandleFromUrl() };
	if(window.location.href.indexOf('dation.nl') === -1) {
		gaData.traffic_type = 'internal';
	}
	gtag('event', eventName, gaData);
});
