import 'bootstrap-select';
import 'bootstrap-select/dist/css/bootstrap-select.css';
import 'bootstrap-select/dist/js/i18n/defaults-nl_NL.js';
// AJAX-based Typeahead support
import 'ajax-bootstrap-select';
import 'ajax-bootstrap-select/dist/css/ajax-bootstrap-select.css';
import 'ajax-bootstrap-select/dist/js/locale/ajax-bootstrap-select.nl-NL';

// set NL as default language for the AJAX plugin of select picker
$.fn.ajaxSelectPicker.defaults.langCode = 'nl-NL';
//By default virtual scroll is Enabled if the options are > 600. This screws over the look & feel, so we turn it off.
$.fn.selectpicker.Constructor.DEFAULTS.virtualScroll = false;