export const storageKey = 'flash-messages';

/**
 * Prepare a message which will be displayed on page refresh/redirect (via display_notifications template)
 * @param {string} message
 * @param {Number} [delay=3000] how long before the message is dismissed, in milliseconds
 * @param {string} [type=success] one of success, info, warning or error
 */
const addFlash = (message, delay = 3000, type = 'success') => {
	const flashMessages = JSON.parse(window.sessionStorage.getItem(storageKey)) || [];
	flashMessages.push({ message, delay, type });
	window.sessionStorage.setItem(storageKey, JSON.stringify(flashMessages));
};

export default addFlash;
export const addNotificationToStorage = addFlash;
