/**
 * This function will create a modal popup by hijacking the original alert functionality from the browser
 * It will allow us to have a consistent look 'n feel accross the system
 * Invoke by using ErrorHandler::Popup($message, optional:http-code/boottrap class)
 *  eg. ErrorHandler::Popup(gettext('U hebt geen wijzigingen aangebracht'), 'info');
 *  or. ErrorHandler::Popup(gettext('U hebt geen wijzigingen aangebracht'), 202);
 *
 * @src    http://stackoverflow.com/questions/10427708/override-function-e-g-alert-and-call-the-original-function
 */
(function($) {
	const _alert = window.alert;

	/**
	 * @param {string} errorMessage - the message to be displayed
	 * @param {string} notificationCaption - the title to be displayed in the modal header
	 * @param {string} notificationClass - one of the bootstrap classes (default, success, danger, info, warning)
	 * @param {number} httpCode
	 * @param {string} httpError
	 * @returns {{notificationCaption: (*|string), notificationClass: (*|string), errorMessage: (*|string), httpCode: (*|number), httpError: (*|string)}}
	 * @constructor
	 */
	function MessageObject(errorMessage, notificationCaption, notificationClass, httpCode, httpError) {
		return {
			notificationCaption: notificationCaption || 'Notificatie',
			notificationClass: notificationClass || 'default',
			errorMessage: errorMessage || '',
			httpCode: httpCode || 200,
			httpError: httpError || ''
		};
	}

	/**
	 * Convert given message into an object
	 * @param {object|string} message - this is either a JSON object, JSON string or a 'normal' string
	 * @return {MessageObject}
	 */
	var parseMessage = function(message) {
		try {
			var data = typeof message === 'object' ? message : JSON.parse(message);

			return new MessageObject(data.error.message, data.error.caption, data.error.class, data.error.code, data.error.type);
		} catch(e) {
			// this is an 'old' non-json invocation of alert('something something something text');
			return new MessageObject(message);
		}
	};

	/**
	 * Overload of javascript alert function, this will try to display the given message in a Bootstrap modal or fallback
	 *  to the default alert if the modal can not be found (e.g. in dialogs or when footer.tpl is not included in the template)
	 * @param errorMessage - this is either a JSON object, JSON string or a 'normal' string
	 */
	window.alert = function(errorMessage) {
		modalAlert(errorMessage);

		// If we want to do a legacy alert, perform this function
		//_alert(str);
	};

	/**
	 * Display a message in a bootstrap modal. Use this function over alert when you're 100% sure that the template contains
	 *  a modal and the given message contains characters that can't be displayed in a default alert message (e.g. HTML)
	 * @param {MessageObject|string} message
	 */
	const modalAlert = function(message) {
		var messageObject = parseMessage(message);

		var myModal = $('#modal', top.window.document);
		if(myModal.length === 0) {
			// modal could not be found; fallback to default alert.
			// this happens for example in a javascript dialog which should be replaced by bootstrap modals somewhere in the future.
			_alert(messageObject.errorMessage);
			return;
		}

		var myHeader = myModal.find('.modal-header');
		var myTitle = myModal.find('.modal-title');
		var myBody = myModal.find('.modal-body');
		var myFooter = myModal.find('.modal-footer');

		myHeader.one('click', '.close', function() {
			myModal.modal('hide');
		});

		myFooter.one('click', '#modal-close', function() {
			myModal.modal('hide');
		});

		myTitle.html('<strong>' + messageObject.notificationCaption + '</strong>');
		myBody.html('<div class="alert alert-' + messageObject.notificationClass + '" role="alert">' + messageObject.errorMessage.replace(/\\n/g, '<br />') + '</div>');
		myFooter.html('<button id="modal-close" type="button" class="btn btn-primary modal-close" data-dismiss="ajaxModal">Sluiten</button>');
		myModal.modal('show');
	};

	// Expose as global
	window.modalAlert = modalAlert;
})(jQuery);
