const showDialog = (message, title, buttonText, successCallback, callbackArguments = []) => {
	const template = `
		<div id="confirmModal" class="modal" role="dialog" tabindex="-1">
			<div id="modal-dialog" class="modal-dialog">
				<div class="modal-content">
					<div id="modal-header" class="modal-header">
						<h4>${title}</h4>
						<button type="button" class="close" data-dismiss>&times;</button>
					</div>
					<div id="modal-confirm-body" class="modal-body">
						<p class="mb-0">${message}</p>
					</div>
					<div id="modal-footer" class="modal-footer">
						<button type="button" class="btn btn-outline-secondary" data-dismiss>Annuleren</button>
						<button type="submit" class="btn btn-primary" id="acceptConfirmModal" data-ok>${buttonText}</button>
					</div>
				</div>
			</div>
		</div>
		`;

	$('body').append($(template));

	const $confirmModal = $('#confirmModal');
	$confirmModal.keydown(e => {
		//Close modal and call back success function if pressed key is Enter.
		if(e.keyCode === 13) {
			removeElement($confirmModal);
			successCallback(...callbackArguments);
		}
	});

	$confirmModal.find('[data-ok]').click(() => {
		removeElement($confirmModal);
		successCallback(...callbackArguments);
	});

	$confirmModal.find('[data-dismiss]').click(() => {
		removeElement($confirmModal);
	});

	$confirmModal.modal({
		show: true
	});
};

const removeElement = $element => {
	$element.modal('hide');
	$element.modal('dispose');
	$element.remove();
};

const bindButtons = confirmModals => {
	$.each(confirmModals, function() {
		const modal = $(this);
		modal.off('click');
		modal.on('click', function(e) {
			e.preventDefault();
			const $this = $(this);
			const href = $this.attr('href') ? $this.attr('href') : $this.attr('data-href');
			const target = $this.attr('target');
			const message = $this.attr('data-message');
			const title = $this.attr('data-confirm-title');
			const buttonText = $this.attr('data-button-text');

			const $frame = $(`iframe[name=${target}]`);

			showDialog(message, title, buttonText, () => {
				if($frame.length) {
					$frame.attr('src', href);
				} else {
					window.location.href = href;
				}
			});

			return false;
		});
	});
};

$(document).ready(function() {
	const $confirmModals = $('.confirm-modal');
	bindButtons($confirmModals);
});

$(document).ajaxComplete(function() {
	const $confirmModals = $('.confirm-modal');
	bindButtons($confirmModals);
});

window.showCustomConfirmDialog = showDialog;
